<template>
	<div class="top">
		<!-- 查询时间类型：今日实时 = 0, 自然日 = 1,自然周 = 2,自然月 = 3,近7天 = 4,近30天 = 5, -->
		<span v-if="titleShow">统计时间：</span>
		<el-select style="margin:0 10px;width:110px;" v-model="timeType" placeholder="请选择" @change='changeTimeOption'>
			<el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value">
			</el-option>
		</el-select>

		<div v-if="timeType==0">
			<span style="margin-right:10px;color:#606266">{{Startdate}} </span>
			<el-button type="text" @click="getRealTime(1)">刷新</el-button>
		</div>

		<el-date-picker v-if="timeType==1" v-model="Datevalue" type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptionsDate"
		 @change="ChangeDatevalue" placeholder="选择日期">
		</el-date-picker>

		<el-date-picker v-if="timeType==2" v-model="Weekvalue" type="week" format="yyyy 第 WW 周" value-format="yyyy-MM-dd"
		 :picker-options="pickerOptionsWeek" @change="ChangeWeekvalue" placeholder="选择周">
		</el-date-picker>

		<el-date-picker v-if="timeType==3" v-model="Monthvalue" format="yyyy-MM" type="month" @change="ChangeMonthvalue"
		 :picker-options="pickerOptionsMonth" placeholder="选择月">
		</el-date-picker>

		<div v-if="timeType==6" style="display:inline-block;">
			<el-date-picker v-model="customizeTime" @change="ChangeCustomize" :picker-options="CustomizeOptionsDate" type="daterange"
			 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
			</el-date-picker>
		</div>

		<div v-if="timeType==4 ||timeType==5||timeType==7||timeType==8||timeType==9" class='recent-days'>
			<span>{{recentStartTime}}</span>
			<span style="margin:0 5px">至</span>
			<span>{{recentEndTime}}</span>
		</div>

	</div>
</template>
<script>
	export default {
		name: 'statisticsTime',
		props: {
			unShowType: {
				type: Array,
				default: []
			},
			titleShow: {
				type: Boolean,
				default: true
			},
			defaultType: {
				type: Number,
				default: 1
			},
		},
		data() {
			return {
				startLineTime: '',
				endLineTime: '',
				recentStartTime: '',
				recentEndTime: '',
				Monthvalue: '',
				customizeTime: [],
				Startdate: '',
				EndDate: '',
				dayNum: '',
				pickerOptionsDate: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				CustomizeOptionsDate: {
					disabledDate(time) {
						return (
							time.getTime() > Date.now() - 24 * 60 * 60 * 1000 ||
							time.getTime() < Date.now() - 24 * 60 * 60 * 1000 * 31
						)
					}
				},
				// pickerOptionsWeek: {},
				pickerOptionsMonth: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 30 * 60 * 60 * 1000
					}
				},
				time: new Date(),
				Weekvalue: '',
				Datevalue: '',
				timeType: 1,
				timeOptions: [{
						value: 0,
						label: '今日实时'
					},
					{
						value: 1,
						label: '自然日'
					},
					{
						value: 2,
						label: '自然周'
					},
					{
						value: 3,
						label: '自然月'
					},
					{
						value: 4,
						label: '近7天'
					},
					{
						value: 5,
						label: '近30天'
					},
					{
						value: 6,
						label: '自定义'
					},
					{
						value: 7,
						label: '最近1个月'
					},
					{
						value: 8,
						label: '最近3个月'
					},
					{
						value: 9,
						label: '最近6个月'
					}
				],
				groupDate: [],
			}
		},
		computed: {
			pickerOptionsWeek: function() {
				let dayNum = this.dayNum
				return {
					firstDayOfWeek: 1,
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * dayNum * 60 * 60 * 1000
					}
				}
			},
		},
		created() {


			// if()
			this.initTimeOption()
			this.initDefaultType()
		},
		methods: {
			// 遍历每一天
			dealDateRange(start, end) {
				var result = [];
				var beginDay = start.split("-");
				var endDay = end.split("-");
				var diffDay = new Date();
				var dateList = new Array;
				var i = 0;
				diffDay.setDate(beginDay[2]);
				diffDay.setMonth(beginDay[1] - 1);
				diffDay.setFullYear(beginDay[0]);
				result.push(start);
				while (i == 0) {
					var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
					diffDay.setTime(countDay);
					dateList[2] = diffDay.getDate();
					dateList[1] = diffDay.getMonth() + 1;
					dateList[0] = diffDay.getFullYear();
					if (String(dateList[1]).length == 1) {
						dateList[1] = "0" + dateList[1]
					}
					if (String(dateList[2]).length == 1) {
						dateList[2] = "0" + dateList[2]
					}
					result.push(dateList[0] + "-" + dateList[1] + "-" + dateList[2]);
					if (dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]) {
						i = 1;
					}
				}
				this.groupDate = result
			},
			// 格式化时间，获取两个日期之间所有月份
			dealMonthRange(startTime, endTime) { //传入的格式YYYY-MM
				var s = startTime.split("-");
				var e = endTime.split("-");
				var min = new Date();
				var max = new Date();
				min.setFullYear(s[0], s[1] * 1 - 1, 1); // 开始日期
				max.setFullYear(e[0], e[1] * 1 - 1, 1); // 结束日期
				var current = min;
				this.groupDate = []
				while (current <= max) {
					var year = current.getFullYear();
					var month = current.getMonth() + 1 < 10 ? '0' + (current.getMonth() + 1) : current.getMonth() + 1;
					this.groupDate.push(year + "-" + month);
					current.setMonth(month);
				}

				// return [dateList, dateList2];
			},
			dealWeekRange(weekGroup) {
				this.groupDate = []
				let weekNums = []
				weekGroup.forEach(t => {
					var year = new Date(t).getFullYear()
					// 将传递的日期字符串变成时间戳
					var timeStamp = Date.parse(t);
					// 根据时间戳得到时间
					var dateTime = new Date(timeStamp);
					// 获取今年的第一天
					var newYear = new Date(dateTime.getFullYear().toString());
					// 计算今天是今年的第多少天
					var yearDay = Math.ceil((dateTime - newYear) / (24 * 60 * 60 * 1000)) + 1;
					// 获取1月1日的星期
					var oneDayAsWeek = newYear.getDay();
					if (oneDayAsWeek === 0) {
						// 星期日距离下星期一相差1天
						yearDay -= 1;
					} else if (oneDayAsWeek === 6) {
						// 星期六距离下星期一相差2天
						yearDay -= 2;
					} else if (oneDayAsWeek === 5) {
						// 星期五距离下星期一相差3天
						yearDay -= 3;
					} else if (oneDayAsWeek === 4) {
						// 星期四距离下星期一相差4天
						yearDay -= 4;
					} else if (oneDayAsWeek === 3) {
						// 星期三距离下星期一相差5天
						yearDay -= 5;
					} else if (oneDayAsWeek === 2) {
						// 星期二距离下星期一相差6天
						yearDay -= 6;
					}
					// 计算剩余的星期数
					var week = Math.ceil(yearDay / 7);
					// 如果当年第一天不是星期一，则需要手动加1周（原因是因为上面去掉了不完整星期的天）
					if (oneDayAsWeek != 1) {
						week++;
					}
					let y = year + '年第' + week + '周'
					weekNums.push(y)
				})
				this.groupDate = weekNums.reverse()
			},
			//筛选时间选择类型
			initTimeOption() {
				this.unShowType.forEach(item => {
					this.timeOptions = this.timeOptions.filter((tt, ii) => {
						if (tt.value != item) {
							return tt
						}
					})
				})
				this.timeType = this.timeOptions[0].value
			},
			initDefaultType() {
				if (this.timeType == 0) { //今日实时

					let time = new Date()
					this.EndDate = this.Startdate = this.formatDate(time)

					this.getRealTime(0)
				} else if (this.timeType == 1) { //自然日
					// 初始化日期,截止到昨天
					let time = new Date()
					this.Datevalue = this.formatDate(new Date(time - 1000 * 60 * 60 * 24))
					this.getDateTime(this.Datevalue)
				} else if (this.timeType == 2) {
					//自然周,截止到上周
					this.Weekvalue = this.formatDate(new Date(new Date() - 1000 * 60 * 60 * 24 * 7))
					this.getWeekDate(this.Weekvalue)
				} else if (this.timeType == 3) {
					// 自然月,截止到上月
					let nowdays = new Date();
					let year = nowdays.getFullYear();
					let month = nowdays.getMonth();
					if (month == 0) {
						month = 12;
						year = year - 1;
					}
					if (month < 10) {
						month = "0" + month;
					}
					let firstDay = new Date(year + "-" + month + "-" + "01"); //上个月的第一天 
					this.Monthvalue = year + "-" + month
					this.getMonthDate(firstDay)
				} else if (this.timeType == 4) {
					//近7天
					this.getRecentDays(7)
				} else if (this.timeType == 6) {
					//自定义
					let time = new Date()
					let startTime = new Date(time - 1000 * 60 * 60 * 24 * 30)
					let lastTime = new Date(time - 1000 * 60 * 60 * 24)
					this.customizeTime = []
					this.customizeTime.push(startTime)
					this.customizeTime.push(lastTime)
					this.getCustomizeTime()
				} else if (this.timeType == 5 || this.timeType == 7) {
					//近30天/1个月
					this.getRecentDays(30)
				} else if (this.timeType == 8) {
					//近3个月
					this.getRecentDays(90)
				} else if (this.timeType == 9) {
					//近6个月
					this.getRecentDays(180)
				}
			},
			//初始化：endLineTime和startLineTime 获取
			getDateTime() {
				//echart显示最近30天的信息
				let time = new Date(this.Datevalue)
				this.startLineTime = this.formatDate(new Date(time - 1000 * 60 * 60 * 24 * 29))
				this.endLineTime = this.Startdate = this.EndDate = this.Datevalue
				this.dealDateRange(this.startLineTime, this.endLineTime)
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate)
			},

			//改变周：获取本周的周一和周末的日期
			getWeekDate(date) {
				let now = new Date(date);
				let nowTime = now.getTime();
				let day = now.getDay();
				let oneDayTime = 24 * 60 * 60 * 1000;
				let MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
				let SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
				this.Weekvalue = this.Startdate = this.formatDate(new Date(MondayTime))
				this.endLineTime = this.EndDate = this.formatDate(new Date(SundayTime))
				// 限制时间
				this.dayNum = day
				//echart显示最近12周的信息，12*7天
				this.startLineTime = this.formatDate(new Date(new Date(SundayTime) - 1000 * 60 * 60 * 24 * 83))

				// weekGroup获取每一周的周日
				let weekGroup = []
				for (let i = 0; i < 12; i++) {
					let oneWeek = this.formatDate(new Date(new Date(SundayTime) - 1000 * 60 * 60 * 24 * 7 * i))
					weekGroup.push(oneWeek)
				}
				this.dealWeekRange(weekGroup)
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate)
			},
			//改变月
			getMonthDate(date) {
				let time = new Date(date);
				let year = time.getFullYear();
				let month = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1);
				//上月第一天
				this.Startdate = this.formatDate(date)
				//上月最后一天
				let myDate = new Date(year, month, 0);
				this.EndDate = this.endLineTime = year + '-' + month + '-' + myDate.getDate()

				//获取过去12个月
				let dateBefore = new Date(time.setMonth(time.getMonth() - 11))
				let yearBefore = dateBefore.getFullYear();
				let monthBefore = (dateBefore.getMonth() + 1) < 10 ? '0' + (dateBefore.getMonth() + 1) : (dateBefore.getMonth() + 1);
				this.startLineTime = yearBefore + '-' + monthBefore + '-' + '01'
				this.dealMonthRange(this.startLineTime, this.endLineTime)
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate)
			},
			//获取今日实时/刷新
			getRealTime(type) {
				let time = new Date()
				let hour = time.getHours()
				this.groupDate = []
				for (let i = 0; i <= hour; i++) {
					this.groupDate.push(i)
				}
				this.Startdate = this.formatDate(time)
				let lastHour = new Date(time.getTime() - 1 * 60 * 60 * 1000)
				let firstHour = new Date(time.setHours(0, 0, 0, 0))
				this.endtime = this.endLineTime = this.formatDate(lastHour)
				this.startLineTime = this.formatDate(firstHour)
				let isRefurbish = type ? true : false
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate,
					isRefurbish)
			},
			//自定义
			getCustomizeTime() {
				this.startLineTime = this.Startdate = this.formatDate(this.customizeTime[0])
				this.endLineTime = this.EndDate = this.formatDate(this.customizeTime[1])
				this.dealDateRange(this.startLineTime, this.endLineTime)
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate)
			},
			//获取近几天
			getRecentDays(days) {
				let time = new Date()
				let lastDay = new Date(time - 3600 * 24 * 1000)
				let firstDay = new Date(time - 3600 * 24 * 1000 * days)
				this.EndDate = this.endLineTime = this.recentEndTime = this.endtime = this.formatDate(lastDay)
				this.Startdate = this.startLineTime = this.recentStartTime = this.formatDate(firstDay)


				let startLineTime = new Date(time - 3600 * 24 * 1000 * 30)
				this.startLineTime = this.formatDate(startLineTime)
				this.dealDateRange(this.startLineTime, this.endLineTime)
				this.$emit('getRangeTime', this.Startdate, this.EndDate, this.timeType, this.startLineTime, this.endLineTime, this.groupDate)
			},
			//初始化
			getData() {

			},
			//改变时间段
			changeTimeOption(e) {
				this.currentTimeType = e
				this.initDefaultType()
			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let h = date.getHours();
				//获取当前分钟数(0-59)
				let m = date.getMinutes();
				//获取当前秒数(0-59)
				let s = date.getSeconds();
				if (this.timeType == 0) {
					return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(h) + ':' + this.formatTen(
						m) + ":" + this.formatTen(s);
				} else {
					return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
				}
			},
			//改变日期
			ChangeDatevalue() {

				this.getDateTime(this.Datevalue)
			},
			//改变周
			ChangeWeekvalue() {
				this.getWeekDate(this.Weekvalue)
			},
			//改变月
			ChangeMonthvalue() {
				this.getMonthDate(this.Monthvalue)
			},
			//自定义
			ChangeCustomize() {
				this.getCustomizeTime()
			}
		}
	}
</script>
<style lang="less" scoped>
	.top {
		padding: 10px 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #fff;
	}

	.recent-days {
		color: #606266;
		margin-left: 10px;
		width: 210px;
		display: inline-block;
		font-size: 16px;
	}
</style>
