<template>
	<div class="static-container">
		<div class="title" style="margin-bottom: 10px;">
			<div class="topWord">{{Name}}</div>
			<div class="secondWord">链接页面：{{MallShopDecorateName}}</div>
		</div>
		<div class="staticFirst">
			<div>
				<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime'></statistics-time>
			</div>
			<div class="overview" v-loading="overviewLoading">
				<div class="interview">
					<div class="left">
						浏览访问
					</div>
					<div class="right">
						<div class="item">
							<span class="lable">访客数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.UV?CurrentOverview.UV:0}}</div>
						</div>
						<div class="item">
							<span class="lable">浏览量</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.PV?CurrentOverview.PV:0}}</div>
						</div>
						<div class="item">
							<span class="lable">人均浏览量</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.PVPerCapita?CurrentOverview.PVPerCapita:0}}</div>
						</div>
					</div>
				</div>
				<div class="interview flow">
					<div class="left">
						流量转化
					</div>
					<div class="right">
						<div class="item">
							<span class="lable">访问-加购转化率</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.UVAndAddToCartMemberCountConversionRate?(CurrentOverview.UVAndAddToCartMemberCountConversionRate+'%'):0}}</div>
						</div>
						<div class="item">
							<span class="lable">访问-下单转化率</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.UVAndSubmitOrderMemberCountConversionRate?(CurrentOverview.UVAndSubmitOrderMemberCountConversionRate +'%'):0}}</div>
						</div>
						<div class="item">
							<span class="lable">访问-支付转化率</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.UVAndPayOrderMemberCountConversionRate?(CurrentOverview.UVAndPayOrderMemberCountConversionRate+'%'):0}}</div>
						</div>
					</div>
				</div>

				<div class="interview flow">
					<div class="left dataflow">
						交易数据
					</div>

					<div class="right">
						<div class="item">
							<span class="lable">加购人数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.AddToCartMemberCount?(CurrentOverview.AddToCartMemberCount):0}}</div>
						</div>
						<div class="item">
							<span class="lable">下单人数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.SubmitOrderMemberCount ?(CurrentOverview.SubmitOrderMemberCount):0}}</div>
						</div>
						<div class="item">
							<span class="lable">下单笔数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.SubmitOrderCount?(CurrentOverview.SubmitOrderCount):0}}</div>
						</div>
						<div class="item">
							<span class="lable">下单金额</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.SubmitOrderMoney?(CurrentOverview.SubmitOrderMoney):0}}</div>
						</div>
						<div class="item">
							<span class="lable">加购-下单转化率</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate?(CurrentOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate+'%'):0}}</div>
						</div>
						<div class="item">
							<span class="lable">支付人数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.PayOrderMemberCount?(CurrentOverview.PayOrderMemberCount):0}}</div>
						</div>
						<div class="item">
							<span class="lable">支付笔数</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.PayOrderCount?(CurrentOverview.PayOrderCount):0}}</div>
						</div>
						<div class="item">
							<span class="lable">支付金额</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.PayOrderMoney?(CurrentOverview.PayOrderMoney):0}}</div>
						</div>
						<div class="item">
							<span class="lable">下单-支付转化率</span>
							<i class="el-icon-warning icon"></i>
							<br>
							<div>{{CurrentOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate?(CurrentOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate+'%'):0}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="staticSecond" style="background: #fff;padding: 15px;">
			<div class="splicer-time">
				<span>统计时间：</span>
				<el-date-picker v-model="Datevalue" @change="ChangeDatevalue" :picker-options="pickerOptionsDate" type="daterange"
				 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<el-button type="primary" @click='Searchgeneralize' style="margin-left: 15px;">查询</el-button>
			</div>
			<!-- 数据趋势 -->
			<div class="survey-box">
				<div style="display: flex;justify-content: space-between;">
					<p class="titles">推广数据明细</p>
					<el-button type="text" @click='ExporteneralizeData'>导出数据</el-button>
				</div>
				
				<div class="marketAnlestics" style="margin-bottom: 10px;">
					<div class="marketBottom">
						<div class="check-label">浏览访问：</div>
						<div class="item-box">
							<div style='display: inline-block;' v-for="item in checksList1" :key='item.index' class="item">
								<!-- <div class="item" v-if="item.show"> -->
									<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
									 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
								<!-- </div> -->
							</div>
						</div>
					</div>
			
					<div class="marketBottom">
						<div class="check-label">流量转化：</div>
						<div class="item-box">
							<div style='display:inline-block;' v-for="item in checksList2" :key='item.index' class="item">
								<!-- <div class="item" v-if="item.show"> -->
									<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
									 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
								<!-- </div> -->
							</div>
						</div>
					</div>
			
					<div class="marketBottom">
						<div class="check-label">交易数据：</div>
						<div class="item-box">
							<div style='display:inline-block;' v-for="item in checksList3" :key='item.index' class="item">
								<!-- <div class="item" v-if="item.show"> -->
									<el-checkbox style='margin-right:20px' @change='handleStructureCheck' :label="item.label" v-model="checks"
									 :disabled="checks.length>4&&!checks.includes(item.label)">{{item.name}}</el-checkbox>
								<!-- </div> -->
							</div>
						</div>
					</div>
				</div>
				<p class="five-more" style="font-size: 13px;">最多只能同时选择5个指标</p>
				
				<el-table :data="generalizeData"  style="width: 100%;"  ref="multipleTable" v-loading='overviewLoading'>
					<el-table-column prop="Name" label="时间" :key='1'>
						<template slot-scope="scope">
							{{scope.row.StatisStartDate}} - {{scope.row.StatisEndDate}}
						</template>
					</el-table-column>
					<el-table-column prop="UV" label="访客数" :key='2' v-if="checks.includes(1)"></el-table-column>
					<el-table-column prop="PV" label="浏览量" :key='3' v-if="checks.includes(2)"></el-table-column>
					<el-table-column prop="PVPerCapita" label="人均浏览量" :key='4' v-if="checks.includes(3)"></el-table-column>
					<el-table-column prop="UVAndAddToCartMemberCountConversionRate" label="访问-加购转化率" :key='5' v-if="checks.includes(4)">
						<template slot-scope="scope">
							{{scope.row.UVAndAddToCartMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="UVAndSubmitOrderMemberCountConversionRate" label="访问-下单转化率" :key='6' v-if="checks.includes(5)">
						<template slot-scope="scope">
							{{scope.row.UVAndSubmitOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="UVAndPayOrderMemberCountConversionRate" label="访问-支付转化率" :key='7' v-if="checks.includes(6)">
						<template slot-scope="scope">
							{{scope.row.UVAndPayOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="UVValue" label="UV价值" :key='8' v-if="checks.includes(7)"></el-table-column>
					<el-table-column prop="AddToCartMemberCount" label="加购人数" :key='9' v-if="checks.includes(8)"></el-table-column>
					<el-table-column prop="SubmitOrderMemberCount" label="下单人数" :key='10' v-if="checks.includes(9)"></el-table-column>
					<el-table-column prop="SubmitOrderCount" label="下单笔数" :key='11' v-if="checks.includes(10)"></el-table-column>
					<el-table-column prop="SubmitOrderMoney" label="下单金额" :key='12' v-if="checks.includes(11)"></el-table-column>
					<el-table-column prop="AddToCartMemberCountAndSubmitOrderMemberCountConversionRate" label="加购-下单转化率" :key='13' v-if="checks.includes(12)">
						<template slot-scope="scope">
							{{scope.row.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMemberCount" label="支付人数" :key='14' v-if="checks.includes(13)"></el-table-column>
					<el-table-column prop="PayOrderCount" label="支付笔数" :key='15' v-if="checks.includes(14)"></el-table-column>
					<el-table-column prop="PayOrderMoney" label="支付金额" :key='16' v-if="checks.includes(15)"></el-table-column>
					<el-table-column prop="SubmitOrderMemberCountAndPayOrderMemberCountConversionRate" label="下单支付转化率" :key='17' v-if="checks.includes(16)">
						<template slot-scope="scope">
							{{scope.row.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate}}%
						</template>
					</el-table-column>
				</el-table>
			<!-- 	<div style="text-align: right;">
					<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="Currentpage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div> -->
			</div>
		</div>
		<div class="staticThird" style="background: #fff;margin-top: 10px;">
			<div>
				<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime2'></statistics-time>
			</div>
			<div class="survey-box" style="padding: 0 10px;">
			<div style="display: flex;justify-content: space-between;">
				<p class="titles">渠道商品数据</p>
				<el-button type="text" @click='ExportProductdata'  v-if='timeType2!=0'>导出数据</el-button>
			</div>
			
			<div class="marketAnlestics" style="margin-bottom: 10px;">
				<div class="marketBottom">
					<div class="check-label">浏览访问：</div>
					<div class="item-box">
						<div style='display: inline-block;' v-for="item in checksList1" :key='item.index' class="item">
							<!-- <div class="item" v-if="item.show"> -->
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck1' :label="item.label" v-model="checks1"
								 :disabled="checks1.length>4&&!checks1.includes(item.label)">{{item.name}}</el-checkbox>
							<!-- </div> -->
						</div>
					</div>
				</div>
						
				<div class="marketBottom">
					<div class="check-label">流量转化：</div>
					<div class="item-box">
						<div style='display:inline-block;' v-for="item in checksList2" :key='item.index' class="item">
							<!-- <div class="item" v-if="item.show"> -->
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck1' :label="item.label" v-model="checks1"
								 :disabled="checks1.length>4&&!checks1.includes(item.label)">{{item.name}}</el-checkbox>
							<!-- </div> -->
						</div>
					</div>
				</div>
						
				<div class="marketBottom">
					<div class="check-label">交易数据：</div>
					<div class="item-box">
						<div style='display:inline-block;' v-for="item in checksList3" :key='item.index' class="item">
							<!-- <div class="item" v-if="item.show"> -->
								<el-checkbox style='margin-right:20px' @change='handleStructureCheck1' :label="item.label" v-model="checks1"
								 :disabled="checks1.length>4&&!checks1.includes(item.label)">{{item.name}}</el-checkbox>
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
			<p class="five-more" style="font-size: 13px;">最多只能同时选择5个指标</p>
			</div>
			<div class="table" style="padding-bottom: 5px;">
				<!-- :default-sort="{prop: `${defaultSortProp}`, order: 'descending'}" -->
				<el-table v-loading="tableLoading" :data="tableData" style="width: 100%">
					<el-table-column :key="1" label="商品" width="300px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src='imgUrl+scope.row.ImgUrl' />
								<div class="right">
									<div class="name">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
			
									</div>
									<span>￥{{scope.row.ProductPrice}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="UV" label="访客数" :key='2' v-if="checks1.includes(1)"></el-table-column>
					<el-table-column prop="PV" label="浏览量" :key='3' v-if="checks1.includes(2)"></el-table-column>
					<el-table-column prop="MemberPv" label="人均浏览量" :key='4' v-if="checks1.includes(3)"></el-table-column>
					<el-table-column prop="AddCartToUvRate" label="访问-加购转化率" :key='5' v-if="checks1.includes(4)">
						<template slot-scope="scope">
							{{scope.row.AddCartToUvRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="SubmintToUvRate" label="访问-下单转化率" :key='6' v-if="checks1.includes(5)">
						<template slot-scope="scope">
							{{scope.row.SubmintToUvRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="PayToUvRate" label="访问-支付转化率" :key='7' v-if="checks1.includes(6)">
						<template slot-scope="scope">
							{{scope.row.PayToUvRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="PayMoneyToUv" label="UV价值" :key='8' v-if="checks1.includes(7)"></el-table-column>
					<el-table-column prop="AddToCartMemberCount" label="加购人数" :key='9' v-if="checks1.includes(8)"></el-table-column>
					<el-table-column prop="SubmitOrderMemberCount" label="下单人数" :key='10' v-if="checks1.includes(9)"></el-table-column>
					<el-table-column prop="SubmitOrderProductCount" label="下单笔数" :key='11' v-if="checks1.includes(10)"></el-table-column>
					<el-table-column prop="SubmitOrderMoney" label="下单金额" :key='12' v-if="checks1.includes(11)"></el-table-column>
					<el-table-column prop="SubmitToAddCartRate" label="加购-下单转化率" :key='13' v-if="checks1.includes(12)">
						<template slot-scope="scope">
							{{scope.row.SubmitToAddCartRate}}%
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMemberCount" label="支付人数" :key='14' v-if="checks1.includes(13)"></el-table-column>
					<el-table-column prop="PayOrderProductCount" label="支付笔数" :key='15' v-if="checks1.includes(14)"></el-table-column>
					<el-table-column prop="PayOrderMoney" label="支付金额" :key='16' v-if="checks1.includes(15)"></el-table-column>
					<el-table-column prop="PayToSubmitRate" label="下单支付转化率" :key='17' v-if="checks1.includes(16)">
						<template slot-scope="scope">
							{{scope.row.PayToSubmitRate}}%
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40]" :page-size="page.size"
				 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>
		</div>


	</div>
</template>
<script>
	import statisticsTime from './StatisticsTime'
	import config from '@/config/index'
	import {
		flowOverview,
		generalizeDatalist,
		PromoteCodeproductList
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			statisticsTime
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				unShowType: [2, 3, 6, 7, 8, 9],
				timeType: 1,
				startDate: '',
				endDate: '',
				PromoteCodeId: '',
				CurrentOverview: {},
				overviewLoading: false,
				Name: '',
				MallShopDecorateName: '',
				Datevalue: [],
				startTime1:'',
				endTime1:'',
				pickerOptionsDate: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				checks1:[1,2,4,5,6],
				checks:[1,2,4,5,6],
				checksList1: [{
						label: 1,
						name: '访客数',
						show: true,
					},
					{
						label: 2,
						name: '浏览量',
						show: true,
					},
					{
						label: 3,
						name: '人均浏览量',
						show: true
					},
				],
				checksList2: [{
						label: 4,
						name: '访问-加购转化率',
						show: true,
					},
					{
						label: 5,
						name: '访问-下单转化率',
						show: true,
					},
					{
						label: 6,
						name: '访问-支付转化率',
						show: true
					},
					{
						label: 7,
						name: 'UV价值',
						show: true
					},
				],
				checksList3: [{
						label: 8,
						name: '加购人数',
						show: true,
					},
					{
						label: 9,
						name: '下单人数',
						show: true,
					},
					{
						label: 10,
						name: '下单笔数',
						show: true
					},
					{
						label: 11,
						name: '下单金额',
						show: true
					},
					{
						label: 12,
						name: '加购-下单转化率',
						show: true
					},
					{
						label: 13,
						name: '支付人数',
						show: true
					},
					{
						label: 14,
						name: '支付笔数',
						show: true
					},
					{
						label: 15,
						name: '支付金额',
						show: true
					},
					{
						label: 16,
						name: '下单-支付转化率',
						show: true
					},
				],
				generalizeData:[],
				startDate2:'',
				endDate2:'',
				page: {
					total: 0,
					size: 20,
					current: 1
				},
				timeType2: 1,
				keyWard:'',
				OrderBy: '',
				IsAsc: '',
				tableData:[],
				tableLoading:false,
				exportUrl: config.EXPORT_URL,
				
			}
		},
		created() {
			this.PromoteCodeId = this.$route.query.id ? this.$route.query.id : ''
			this.Name = this.$route.query.Name ? this.$route.query.Name : ''
			this.MallShopDecorateName = this.$route.query.MallShopDecorateName ? this.$route.query.MallShopDecorateName : ''
			let time = new Date()
			// let startTime = new Date(time - 1000 * 60 * 60 * 24 * 14)
			let lastTime = new Date(time - 1000 * 60 * 60 * 24)
			this.Datevalue = []
			this.Datevalue.push(lastTime)
			this.Datevalue.push(lastTime)
			this.getCustomizeTime()
		},
		methods: {
			//导出推广明细
			ExporteneralizeData(){
				let url = this.exportUrl + '/pc/dataStatisPromoteCode/list?' +
					'&PromoteCodeId=' + this.PromoteCodeId +
					'&StartDate=' + (this.startTime1?this.startTime1:'')+
					'&EndDate=' + (this.endTime1?this.endTime1:'')
				console.log(url)
				window.open(url)
			},
			//导出商品数据
			ExportProductdata(){
				let url = this.exportUrl + '/pc/dataStatisPromoteCode/productExport?' +
					'&PromoteCodeId=' + this.PromoteCodeId +
					'&TimeType=' + this.timeType2 + 
					'&Time=' + (this.startDate2?this.startDate2:'')+
					'&KeyWord=' + this.keyWard
					
					console.log(url,'网址url')
				window.open(url)
			},
			handleSizeChange(val) {
				this.page.size = val
				this.getProductInfor()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getProductInfor()
			},
			getRangeTime2(startDate, endDate, timeType, startLineTime, endLineTime, groupDate, isRefurbish) {
				console.log('startDate2', startDate, endDate, timeType)
				this.startDate2 = startDate
				this.endDate2 = endDate
				this.timeType2 = timeType
				this.page.current = 1
				this.getProductInfor()
			},
			// 获取商品信息
			async getProductInfor() {
				try {
					this.tableLoading = true
					let data = {
						PromoteCodeId:this.PromoteCodeId,
						TimeType: this.timeType2,
						Time: this.startDate2,
						KeyWord: this.keyWard,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
			
					let result = await PromoteCodeproductList(data)
					this.tableData = result.Result.Results
					this.page.total = result.Result.Total
			
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.tableLoading = false
				}
			
			},
			handleStructureCheck1() {
				console.log(this.checks1,'选中的checkbox')
				let checks1 = this.checks1.sort((x, y) => {
					return x - y
				})
				this.getProductInfor()
			},
			
			handleStructureCheck() {
				let checks = this.checks.sort((x, y) => {
					return x - y
				})
				this.getdataChecklist()
			},
			//获取推广明细数据
			async getdataChecklist(){
				try{
					this.overviewLoading = true
					let data ={
						PromoteCodeId:this.PromoteCodeId,
						StartDate:this.startTime1,
						EndDate:this.endTime1
					}
					console.log(data,'传递的参数')
					let result = await generalizeDatalist(data)
					console.log(result,'推广的明细吖')
					if(result.IsSuccess){
						this.generalizeData = result.Result
						this.generalizeData.map(item =>{
							item.StatisStartDate = item.StatisStartDate.split(" ")[0]
							item.StatisEndDate =   item.StatisEndDate.split(" ")[0]
							return item
						})
					}
					
				}catch(err){
					
				}finally{
					this.overviewLoading = false
				}
			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
			},
			getCustomizeTime() {
				this.startTime1 = this.formatDate(this.Datevalue[0])
				this.endTime1 = this.formatDate(this.Datevalue[1])
				
				console.log(this.startTime1,this.endTime1,'推广明细开始结束时间')
				this.getdataChecklist()
			},
			//改变日期
			ChangeDatevalue() {
				this.getCustomizeTime()
			},
			//查询推广明细
			Searchgeneralize(){
				console.log('怎么没有执行吗')
				this.getdataChecklist()
			},
			//刷新数据
			getRangeTime(startDate, endDate, timeType) {
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				this.getdatas()
			},
			// 获取统计数据概况
			async getdatas() {
				try {
					this.overviewLoading = true;
					let data = {
						PromoteCodeId: this.PromoteCodeId,
						TimeType: this.timeType,
						StartDate: this.timeType == 0 ? '' : this.startDate,
						EndDate: this.timeType == 0 ? '' : this.endDate
					}
					let result = await flowOverview(data)
					if (result.IsSuccess) {
						this.CurrentOverview = result.Result
						console.log(this.CurrentOverview, '6666666')
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.overviewLoading = false
				}

			},
		},
	}
</script>
<style lang="less" scoped>
	.static-container {
		.title {
			background: #fff;
			padding: 15px 0 15px 15px;
			font-size: 14px;

			.topWord {
				font-weight: bold;
				color: #000;
				font-size: 17px;
				margin-bottom: 12px;
			}
		}

		.overview {
			padding: 15px;
			background-color: #fff;
			margin-bottom: 10px;

			.interview {
				background-color: rgb(225, 238, 251);
				overflow: hidden;
				display: flex;
				flex-direction: row;

				.left {
					width: 80px;
					background-color: #409EFF;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					font-size: 14px;
				}

				.right {
					height: 100%;
					color: #606266;
					width: calc(100% - 80px);
					align-items: center;

					.item {
						padding: 25px;
						display: inline-block;
						width: 25%;
					}

					.item:nth-child(5n) {
						word-wrap: break-word;
						word-break: normal;
					}

					.lable {
						line-height: 20px;
						font-size: 12px;
					}

					.icon {
						font-size: 14px;
						color: #999;
						margin-left: 5px;
						cursor: pointer;
					}

					.num {
						line-height: 30px;
						font-size: 18px;
					}

					.compare {
						line-height: 20px;
						font-size: 12px;
					}

					.compare-hidden {
						display: none;
					}
				}
			}

			.flow {
				margin-top: 5px;
				background-color: rgb(225, 251, 231);

				.left {
					background-color: rgb(83, 172, 108);
				}

				.dataflow {
					background-color: rgb(83, 107, 172);
				}
			}
		}
		
		.marketAnlestics{
			font-size: 15px;
			padding: 10px;
			background: rgb(238,238,238);
			.marketBottom{
				display: flex;
				margin-bottom: 10px;
				.item-box{
					display: flex;
					width: 60%;
					flex-wrap:wrap;
					// justify-content: space-between;
					.item{
						width: 25%;
						margin-bottom:8px;
					}
				}
			}
		}
		
		.table {
			overflow: hidden;
		
			.dialog-name-content {
				display: flex;
				flex-direction: row;
		
				img {
					width: 70px;
					height: 70px;
					object-fit: contain;
					margin-right: 10px;
				}
		
				.name {
					width: 170px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
		
				span {
					color: #E51C23;
					font-size: 12px;
				}
			}
		}

	}
</style>
